import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'unfetch/polyfill';

import './index.css';
import MomentUtils from '@date-io/moment';
import { CssBaseline, MuiThemeProvider, withStyles } from '@material-ui/core';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IDLE_KEY, IDLE_TIMEOUT } from '@rss/common';
import { create } from 'jss';
import React, { useState, lazy, Suspense } from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import IdleTimer from 'react-idle-timer';
import { JssProvider } from 'react-jss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { ReadOnlyClient, ReadWriteClient } from './apollo';
import IdleTimeoutDialog from './components/IdleTimeoutDialog';
import RssLoader from './components/RssLoader';
import theme from './theme';
import * as serviceWorker from './serviceWorker';
import { isIdleSession, updateTimeoutTime, SignOut, shouldShowWarning } from './shared/auth.helper';
import config from './config';

const App = lazy(() => import('./App'));
const Public = lazy(() => import('./Public'));
const ReadOnly = lazy(() => import('./ReadOnly'));

const styles = () => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      fontFamily: 'Roboto, Helvetica Neue Light, Arial, Lucida Grande, sans-serif',
      height: '100%',
      display: 'flex',
    },
    '#root': {
      display: 'flex',
      flexGrow: 1,
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    a: {
      textDecoration: 'none',
    },
    '@media print': {
      body: {
        height: 'inherit',
        display: 'block',
      },
      '@page :first': {
        marginTop: 10,
      },
      '@page': {
        marginTop: 40,
        marginBottom: 40,
      },
      '#root': {
        display: 'block',
        overflowX: 'visible',
        overflowY: 'visible',
      },
      html: {
        height: 'unset',
        fontSize: '12px',
      },
    },
  },
});

const Root = withStyles(styles)(() => {
  const generateClassName = createGenerateClassName();
  const jss = create(jssPreset());
  jss.options.insertionPoint = 'jss-insertion-point';

  let idleTimer = null;
  const [idleTimeoutInMinutes, setIdleTimeoutInMinutes] = useState(localStorage.getItem(IDLE_KEY.IDLE_MINUTES_KEY));
  const [showIdleWarning, setShowIdleWarning] = useState(false);

  if (idleTimeoutInMinutes) {
    if (!showIdleWarning && isIdleSession(idleTimeoutInMinutes)) {
      SignOut();
    }
  }

  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Suspense fallback={<RssLoader />}>
            {idleTimeoutInMinutes && (
              <IdleTimer
                ref={(r) => {
                  idleTimer = r;
                }}
                timeout={(idleTimeoutInMinutes - IDLE_TIMEOUT.IDLE_TIMOUT_DURATION_MINUTES) * 60000}
                onAction={() => {
                  updateTimeoutTime(idleTimer.getLastActiveTime());
                }}
                onIdle={() => {
                  if (shouldShowWarning()) {
                    setShowIdleWarning(true);
                  }
                }}
              />
            )}
            <Switch>
              <Route
                path="/public"
                component={() => (
                  <ApolloProvider client={ReadOnlyClient}>
                    <Route component={Public} />
                  </ApolloProvider>
                )}
              />
              <Route
                path="/read-only"
                component={() => (
                  <ApolloProvider client={ReadOnlyClient}>
                    <Route component={ReadOnly} />
                  </ApolloProvider>
                )}
              />
              <Route
                component={() => (
                  <ApolloProvider client={ReadWriteClient}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <App
                        onUpdateIdleTimeout={(timeout) => {
                          localStorage.setItem(IDLE_KEY.IDLE_MINUTES_KEY, timeout);
                          setIdleTimeoutInMinutes(timeout);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </ApolloProvider>
                )}
              />
            </Switch>

            {showIdleWarning && (
              <IdleTimeoutDialog
                duration={IDLE_TIMEOUT.IDLE_TIMOUT_DURATION_MINUTES * 60}
                onClose={() => setShowIdleWarning(false)}
              />
            )}
          </Suspense>
        </BrowserRouter>
      </MuiThemeProvider>
    </JssProvider>
  );
});

(async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: config.LD_CLIENT_ID });
  ReactDOM.render(
    <LDProvider>
      <Root />
    </LDProvider>,
    document.getElementById('root'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
